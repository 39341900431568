<template>
  <b-form @submit.prevent="submit" autocomplete="off" class="pl-3 pr-3 col col-sm-12">
    <b-row>
      <b-col lg="6" md="12" sm="12">
        <b-form-group :label="$t('Fecha')">
          <b-form-datepicker
            required
            v-model="medicion.fecha"
            :placeholder="$t('Selecciona una fecha')"
            :date-format-options="$dateFormatOptions"
            class="mb-2"
            locale="es"
          ></b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group label="Valor real">
          <b-form-input required type="text" v-model="medicion.valor"></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('Causa')">
          <b-form-select v-model="medicion.causa" :options="comboCausas"> </b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="6">
        <b-form-group :label="$t('Zona')">
          <b-form-select v-model="medicion.zona" :options="comboZonas"> </b-form-select>
        </b-form-group>
      </b-col>
      <b-col lg="6" md="12" sm="12">
        <b-form-group :label="$t('Observaciones')">
          <b-form-textarea v-model="medicion.observaciones" rows="3" max-rows="6"></b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <botonera-edicion :url-cancelar="`/administracion/indicadores/${this.$route.params.indicadorId}/mediciones`">
    </botonera-edicion>
  </b-form>
</template>
<script>
import * as apiZonas from "@/services/zonas.js";
import * as apiCausas from "@/services/causas.js";
import Vue from "vue";

export default {
  props: {
    medicion: Object,
  },
  data() {
    return {
      zonas: [],
      causas: [],
    };
  },
  computed: {
    comboZonas() {
      return this.zonas.map((zona) => {
        return {
          text: zona.nombre,
          value: zona.nombre,
        };
      });
    },
    comboCausas() {
      return this.causas.map((causa) => {
        return {
          text: causa.nombre,
          value: causa.nombre,
        };
      });
    },
  },
  methods: {
    async listarZonas() {
      const response = await apiZonas.listar();
      this.zonas = response.zonas;
    },
    async listarCausas() {
      const response = await apiCausas.listar();
      this.causas = response.causas;
    },
    submit() {
      this.$emit("submit");
    },
  },
  created() {
    this.listarZonas();
    this.listarCausas();
  },
};
</script>
