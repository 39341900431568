<template>
  <page fluid="lg" :title="title">
    <formulario :medicion="medicion" @submit="insertar"></formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiMediciones from "@/services/mediciones.js";
import * as apiIndicadores from "@/services/indicadores.js";

export default {
  components: { Formulario },
  data() {
    return {
      indicador: {},
      medicion: apiMediciones.crear(),
    };
  },
  methods: {
    async insertar() {
      this.medicion.indicadorId = this.indicador.id;
      let ok = await apiMediciones.insertar(this.medicion);
      if (ok) {
        this.$emit("userMessage", "Se ha creado la medición");
        this.$router.push({
          path: "../mediciones",
        });
      }
    },
    async cargarIndicador(indicadorId) {
      this.indicador = await apiIndicadores.cargar(indicadorId);
    },
  },
  async mounted() {
    await this.cargarIndicador(this.$route.params.indicadorId);
  },
  computed: {
    title() {
      return this.indicador.nombre + ": Crear mediciones";
    },
  },
};
</script>
