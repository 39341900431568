import { listado } from "./listado";
import { api } from "./backend";

export function crear() {
  return {
    id: 0,
    nombre: "",
    activa: 1,
  };
}

export async function listar(params) {
  return await listado("causa/listar", params, { activa: 1 });
}

export async function cargar(id) {
  const response = await api.get("causa/cargar", { id });
  return response.data;
}

export async function insertar(causa) {
  const response = await api.post("causa/insertar", { causa });
  return response.data;
}

export async function actualizar(causa) {
  const response = await api.post("causa/actualizar", { causa });
  return response.data;
}

export async function eliminar(id) {
  await api.post("causa/eliminar", { id });
}
