import { listado } from "./listado";
import { api } from "./backend";
import {parseDate} from './date';

export function crear() {
  return {
    id: 0,
    indicadorId: "",
    fecha: new Date(),
    valor: 0,
    zona: "",
    causa: "",
  };
}

export async function listar(indicadorId, params) {
  params.filtros = { indicadorId };
  return await listado("medicion/listar", params);
}

export async function cargar(id) {
  const response = await api.get("medicion/cargar", { id });
  const row = response.data;

  row.fecha = parseDate(row.fecha);
  row.creadaEl = parseDate(row.creadaEl);

  return row;
}

export async function insertar(medicion) {
  const response = await api.post("medicion/insertar", { medicion });
  return response.data;
}

export async function actualizar(medicion) {
  const response = await api.post("medicion/actualizar", { medicion });
  return response.data;
}

export async function eliminar(id) {
  await api.post("medicion/eliminar", { id });
}
